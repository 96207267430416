import { isEqual, clone } from 'lodash'
import { Types } from './action'

const Reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case Types.SET_DEBUG_MODE: {
      return Object.assign({}, state, {
        debugMode: payload,
      })
    }

    case Types.SET_APP_READY: {
      return Object.assign({}, state, {
        isAppReady: true,
      })
    }

    case Types.SET_MOBILE_DEVICE: {
      const isMobileDevice = payload

      return Object.assign({}, state, {
        isMobileDevice,
      })
    }

    case Types.SET_SCROLL: {
      const isScrollActive = payload

      return Object.assign({}, state, {
        isScrollActive,
      })
    }

    case Types.SET_VERSION: {
      const version = payload

      return Object.assign({}, state, {
        version,
      })
    }

    case Types.SET_ACTIVE_VIEW: {
      return Object.assign({}, state, {
        activeView: payload,
      })
    }

    case Types.SET_VIEWPORT: {
      const { width, height } = payload

      return Object.assign({}, state, {
        viewport: {
          width,
          height,
        },
      })
    }

    case Types.SET_BODY: {
      const { width, height, scaleFactor } = payload

      return Object.assign({}, state, {
        body: {
          width,
          height,
          scaleFactor,
        },
      })
    }

    case Types.SET_LOOKS: {
      const { looks } = payload

      return Object.assign({}, state, {
        looks,
      })
    }

    case Types.SET_INTRO: {
      const intro = payload

      return Object.assign({}, state, {
        intro,
      })
    }

    case Types.SET_VIEW1: {
      const { width, height, marquees, links, blocks } = payload

      return Object.assign({}, state, {
        view1: {
          width,
          height,
          marquees,
          links,
          blocks,
        },
      })
    }

    case Types.SET_VIEW2: {
      const { links, groups } = payload

      return Object.assign({}, state, {
        view2: {
          links,
          groups,
        },
      })
    }

    case Types.SET_OVERLAY: {
      const overlay = payload

      return Object.assign({}, state, {
        overlay,
      })
    }

    case Types.SET_VIEWS: {
      const views = payload

      return Object.assign({}, state, {
        views,
      })
    }

    case Types.ADD_TRIGGER: {
      const triggers = clone(state.triggers)

      triggers[payload.type] = payload

      if (isEqual(triggers, state.triggers)) {
        return state
      }

      return Object.assign({}, state, {
        triggers,
      })
    }

    case Types.PLAY_EASTEREGG: {
      if (state.easterEgg) {
        return state
      }

      return Object.assign({}, state, {
        easterEgg: true,
      })
    }

    case Types.STOP_EASTEREGG: {
      if (!state.easterEgg) {
        return state
      }

      return Object.assign({}, state, {
        easterEgg: false,
      })
    }

    default:
      return state
  }
}

export default Reducer
