import { createStore as reduxCreateStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './reducer'

const initialState = {
  debugMode: false,

  isAppReady: false,

  isMobileDevice: false,

  isScrollActive: false,

  version: null,

  activeView: {
    key: null,
  },

  viewport: {
    width: 0,
    height: 0,
  },

  body: {
    width: 0,
    height: 0,
    scaleFactor: 0,
  },

  looks: [],

  intro: null,

  view1: null,

  view2: null,

  overlay: null,

  views: null,

  triggers: {},

  easterEgg: null,
}

const createStore = () => {
  return reduxCreateStore(reducer, initialState, applyMiddleware(thunk))
}

const store = createStore()

export default store
