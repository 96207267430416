import {
  groupBy,
  filter,
  orderBy,
  isEqual,
  find,
  get,
  set,
  clone
} from 'lodash'

const Types = {
  SET_DEBUG_MODE: 'SET_DEBUG_MODE',
  SET_APP_READY: 'SET_APP_READY',
  SET_MOBILE_DEVICE: 'SET_MOBILE_DEVICE',
  SET_SCROLL: 'SET_SCROLL',
  SET_VERSION: 'SET_VERSION',
  SET_ACTIVE_VIEW: 'SET_ACTIVE_VIEW',
  SET_VIEWPORT: 'SET_VIEWPORT',
  SET_BODY: 'SET_BODY',
  SET_LOOKS: 'SET_LOOKS',
  SET_INTRO: 'SET_INTRO',
  SET_VIEW1: 'SET_VIEW1',
  SET_VIEW2: 'SET_VIEW2',
  SET_OVERLAY: 'SET_OVERLAY',
  SET_VIEWS: 'SET_VIEWS',
  ADD_TRIGGER: 'ADD_TRIGGER',
  PLAY_EASTEREGG: 'PLAY_EASTEREGG',
  STOP_EASTEREGG: 'STOP_EASTEREGG',
}

const Actions = {}

Actions.setDebugMode = payload => ({
  type: Types.SET_DEBUG_MODE,
  payload,
})

Actions.setAppReady = payload => ({
  type: Types.SET_APP_READY,
  payload,
})

Actions.setMobileDevice = payload => ({
  type: Types.SET_MOBILE_DEVICE,
  payload,
})

Actions.setScroll = payload => ({
  type: Types.SET_SCROLL,
  payload,
})

Actions.setVersion = payload => ({
  type: Types.SET_VERSION,
  payload,
})

Actions.setActiveView = payload => ({
  type: Types.SET_ACTIVE_VIEW,
  payload,
})

Actions.changeView = payload => {
  return function (dispatch, getState) {
    const { version, views } = getState()

    const { VIEW_DEFAULT, VIEW_1, VIEW_2, VIEW_3, VIEW_OVERLAY } = views

    let key

    const isMobile = version === 'mobile'

    switch (get(payload, 'key')) {
      case VIEW_DEFAULT:
      case VIEW_1:
      case VIEW_3:
        key = isMobile ? VIEW_3 : VIEW_1
        break

      case VIEW_2:
        key = VIEW_2
        break

      case VIEW_OVERLAY:
        key = isMobile ? VIEW_3 : VIEW_OVERLAY
        break

      default:
        key = get(payload, 'key')
    }

    set(payload, 'key', key)

    dispatch(Actions.setActiveView(payload))
  }
}

Actions.setViewport = payload => ({
  type: Types.SET_VIEWPORT,
  payload,
})

Actions.setBody = payload => ({
  type: Types.SET_BODY,
  payload,
})

Actions.setLooks = payload => ({
  type: Types.SET_LOOKS,
  payload,
})

Actions.setIntro = payload => ({
  type: Types.SET_INTRO,
  payload,
})

Actions.setView1 = payload => ({
  type: Types.SET_VIEW1,
  payload,
})

Actions.setView2 = payload => ({
  type: Types.SET_VIEW2,
  payload,
})

Actions.setOverlay = payload => ({
  type: Types.SET_OVERLAY,
  payload,
})

Actions.setViews = payload => ({
  type: Types.SET_VIEWS,
  payload,
})

Actions.addTrigger = payload => ({
  type: Types.ADD_TRIGGER,
  payload,
})

Actions.playEasterEgg = payload => ({
  type: Types.PLAY_EASTEREGG,
  payload,
})

Actions.stopEasterEgg = payload => ({
  type: Types.STOP_EASTEREGG,
  payload,
})

Actions.fireTrigger = (() => {
  let buffer = []
  let queue = []

  const insert = trigger => {
    buffer.push(trigger)
    queue = filter(buffer, 'priority')
    queue = orderBy(queue, 'priority', 'desc')
    queue = groupBy(queue, 'type')
  }

  const isBuffered = trigger => {
    return find(buffer, trigger)
  }

  const getFirstByType = type => {
    return queue[type] ? queue[type][0] : null
  }

  const isFirst = trigger => {
    return isEqual(getFirstByType(trigger.type), trigger)
  }

  const emptyBuffer = trigger => {
    buffer = filter(buffer, item => item.type !== trigger.type)
    queue[trigger.type] = []
  }

  return payload => {
    const _payload = clone(payload)

    return function (dispatch) {
      if (!isBuffered(_payload)) {
        insert(_payload)

        window.requestAnimationFrame(() => {
          if (isFirst(_payload)) {
            dispatch(Actions.addTrigger(_payload))
            emptyBuffer(_payload)
          }
        })
      } else {
        return Promise.resolve()
      }
    }
  }
})()

Actions.openLook = payload => {
  return function (dispatch, getState) {
    const { version, views } = getState()

    const { VIEW_3 } = views

    const isMobile = version === 'mobile'

    if (isMobile) {
      dispatch(
        Actions.setActiveView({
          key: VIEW_3,
          look: payload,
        })
      )
    } else {
      dispatch(Actions.setOverlay(payload))
    }
  }
}

export { Actions, Types }
